import { Outlet } from "react-router-dom"

export default function Page() {

  return (
    <div>
      <p>Reporting</p>
      <Outlet />
    </div>
  )
}