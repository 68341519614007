import { useAuth0 } from '@auth0/auth0-react';
import { RoutePath } from '../models/Route';

export const Logout = () => {
  const { logout } = useAuth0();

  const handleClick = () => {
    logout({ returnTo: `${window.origin}${RoutePath.LOGGED_OUT}`});    
  }

  return (
    <div style={{cursor: 'pointer'}} onClick={handleClick}>
      Log out
    </div>
  );
};