export interface Route {
  name: RouteName;
  label: RouteLabel;
  path: RoutePath;
  icon?: string;
  description?: string;
}

export enum RoutePath {
  //public
  ROOT = "/",
  LOGGED_OUT = '/logged-out',

  //protected
  HOME = '/home',
  PARTS = '/parts',
  PARTS_SEARCH = '/parts/search',
  PARTS_SPARES = '/parts/spares',
  BASKETS = '/baskets',
  BASKETS_OPEN = '/baskets/open',
  BASKETS_ORDERED = '/baskets/ordered',
  BASKETS_MASTER = '/baskets/master',
  ORDERS = '/orders',
  REPORTING = '/reporting',
  REPORTING_TURNOVER = '/reporting/turnover',
  PROJECTS = '/projects',
  PROJECTS_SEARCH = '/projects/search',
  PROJECTS_OVERVIEW = '/projects/overview',
  QUALITY_CONTROL = '/qualitycontrol',
  UPLOADS = '/uploads',
  UPLOADS_ASSEMBLIES = '/uploads/assemblies',
  UPLOADS_ORDER_REQUESTS = '/uploads/order-requests',
  UPLOADS_FILES = '/uploads/files',
}

export enum RouteName {
  HOME = 'home',
  PARTS = 'parts',
  PARTS_SEARCH = 'parts-search',
  PARTS_SPARES = 'parts-spares',
  BASKETS = 'baskets',
  BASKETS_OPEN = 'baskets-open',
  BASKETS_ORDERED = 'baskets-ordered',
  BASKETS_MASTER = 'master-baskets',
  ORDERS = 'orders',
  REPORTING = 'reporting',
  REPORTING_TURNOVER = 'reporting-turnover',
  PROJECTS = 'projects',
  PROJECTS_SEARCH = 'projects-search',
  PROJECTS_OVERVIEW = 'projects-overview',
  QUALITY_CONTROL = 'qualitycontrol',
  UPLOADS = 'uploads',
  UPLOADS_ASSEMBLIES = 'uploads-assemblies',
  UPLOADS_ORDER_REQUESTS = 'uploads-order-requests',
  UPLOADS_FILES = 'uploads-files',
}

export enum RouteLabel {
  HOME = 'Home',
  PARTS = 'Parts',
  PARTS_SEARCH = 'Search',
  PARTS_SPARES = 'Spares',
  BASKETS = 'Baskets',
  BASKETS_OPEN = 'Open Baskets',
  BASKETS_ORDERED = 'Ordered Baskets',
  BASKETS_MASTER = 'Master Baskets',
  ORDERS = 'Orders',
  REPORTING = 'Reporting',
  REPORTING_TURNOVER = 'Turnover',
  PROJECTS = 'Projects',
  PROJECTS_SEARCH = 'Search',
  PROJECTS_OVERVIEW = 'Overview',
  QUALITY_CONTROL = 'Quality Control',
  UPLOADS = 'Uploads', 
  UPLOADS_ASSEMBLIES = 'Assemblies',
  UPLOADS_ORDER_REQUESTS = 'Order Requests',
  UPLOADS_FILES = 'Files',

  LOGIN = 'Login',
}





