import styles from './Logo.module.css';
import logoImg from '../../assets/imgs/logo-sidebar.png';
import { NavLink } from 'react-router-dom';
import { RoutePath } from '../../models/Route';


export const Logo = ():JSX.Element => {

  return(
    <div className={styles.logo}>
      <NavLink to={RoutePath.HOME}>
      <img
        src={logoImg}
        alt="Komec/Argus Logo"
        width={230}
        />
      </NavLink>
    </div>
  )
}