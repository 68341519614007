import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import styles from './Auth.module.css'
import logo from '../assets/imgs/logo.png';


export const Login = (): JSX.Element => {
  const { loginWithRedirect } = useAuth0();

  useEffect (() => {
    loginWithRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  
  return (
    <div className={styles.container}>
      <div><img src={logo} alt="logo" /></div>
      <div className={styles.message}>
      </div>
    </div>
  )
};