import logo from '../assets/imgs/logo.png';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../models/Route';
import styles from './Auth.module.css'


export const LoggedOut = () => {
  const nav = useNavigate();

  const handleClick = () => {
    nav(RoutePath.HOME); 
  }

  return (
    <div className={styles.container}>
      <div><img src={logo} alt="logo" /></div>
      <div className={styles.message}>
        <div>Logged out successfully.</div>
        <button onClick={handleClick}>Log in</button>
      </div>
    </div>
  );
};