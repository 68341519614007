import { RouteLabel, RoutePath } from "../../models/Route";
import { Logo } from "./Logo";
import { NavLink } from "react-router-dom";
import { Box, Collapse, List, ListItemButton, ListItemText } from "@mui/material";
import { useState } from "react";
import styled from "@emotion/styled";
import {ChevronRight, ExpandMore} from '@mui/icons-material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShoppingBasketSharpIcon from '@mui/icons-material/ShoppingBasketSharp';
import ContentPasteSharpIcon from '@mui/icons-material/ContentPasteSharp';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SwapHorizSharpIcon from '@mui/icons-material/SwapHorizSharp';
import LockOpenSharpIcon from '@mui/icons-material/LockOpenSharp';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import EuroSharpIcon from '@mui/icons-material/EuroSharp';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

const orange = '#F47635' //todo = mui theme
const orangeHover = '#F47635' //'#f6915d' 
const grey = '#616161'
const darkBackground = '#1E1E1E'

const StyledListItemButton = styled(ListItemButton)<StyledButtonProps>(({theme, isActive}) => ({
  backgroundColor: isActive ? orange : 'transparent',
  padding: '20px 45px',
  height: '40px',
  borderBottom: `1px solid #1b1b1b`,
  '&:hover': {
    backgroundColor: orangeHover
  },
  transition: 'background-color 0.3s ease-in-out'
}));

const StyledHeaderButton = styled(ListItemButton)<StyledButtonProps>(({theme, isActive}) => ({
  backgroundColor: isActive ? orange : grey,
  height: '40px',
  marginTop: '20px',
  '&:hover': {
    backgroundColor: orangeHover
  },
  transition: 'background-color 0.3s ease-in-out'
}));

type StyledButtonProps = {
  isActive?: boolean;
}

const StyledSidebar = styled(Box)(({theme}) => ({
  height: '100vh',
  width: '300px',
  backgroundColor: darkBackground,
  color: 'white',
  display: 'grid',
  gridTemplateColumns: 'auto',
  gridTemplateRows: '100px 1fr'
}));


export const Sidebar = ():JSX.Element => {
  const [open, setOpen] = useState({
    parts: false,
    baskets: false,
    reporting: false,
    projects: false,
    quality: false,
    uploads: false,
  });

  return (
    <StyledSidebar>
      <Logo />

      <List>
        <StyledHeaderButton
          onClick={() => setOpen({ ...open, parts: !open.parts })}
          disableRipple
        >
          <Box sx={{pr: 1}}><SettingsOutlinedIcon fontSize="small"/></Box>
          <ListItemText>{RouteLabel.PARTS}</ListItemText>
          {open.parts ? <ExpandMore fontSize="small"/> : <ChevronRight fontSize="small"/>}
        </StyledHeaderButton>
        
        <Collapse in={open.parts}>
          <NavLink to={RoutePath.PARTS_SEARCH}>
            {({ isActive }) => (
              <StyledListItemButton 
                disableRipple
                isActive={isActive}
              >
              <Box sx={{pr: 1}}><SearchOutlinedIcon fontSize="small"/></Box>
              <ListItemText>{RouteLabel.PARTS_SEARCH}</ListItemText>
              </StyledListItemButton>
            )}
          </NavLink>

          <NavLink to={RoutePath.PARTS_SPARES}>
            {({ isActive }) => (
              <StyledListItemButton 
                disableRipple
                isActive={isActive}
              >
                <Box sx={{pr: 1}}><SwapHorizSharpIcon fontSize="small"/></Box>
                <ListItemText>{RouteLabel.PARTS_SPARES}</ListItemText>
              </StyledListItemButton>
            )}
          </NavLink>
        </Collapse>

        <StyledHeaderButton
          onClick={() => setOpen({ ...open, baskets: !open.baskets })}
          disableRipple
        >
          <Box sx={{pr: 1}}><ShoppingBasketSharpIcon fontSize="small"/></Box>
          <ListItemText>{RouteLabel.BASKETS}</ListItemText>
          {open.baskets ? <ExpandMore fontSize="small"/> : <ChevronRight fontSize="small"/>}
        </StyledHeaderButton>

        <Collapse in={open.baskets}>
          <NavLink to={RoutePath.BASKETS_OPEN}>
            {({ isActive }) => (
              <StyledListItemButton 
                disableRipple
                isActive={isActive}
              >
                <Box sx={{pr: 1}}><LockOpenSharpIcon fontSize="small"/></Box>
                <ListItemText>{RouteLabel.BASKETS_OPEN}</ListItemText>
              </StyledListItemButton>
            )}
          </NavLink>

          <NavLink to={RoutePath.BASKETS_ORDERED}>
            {({ isActive }) => (
              <StyledListItemButton 
                disableRipple
                isActive={isActive}
              >
                <Box sx={{pr: 1}}><LockOutlinedIcon fontSize="small"/></Box>
                <ListItemText>{RouteLabel.BASKETS_ORDERED}</ListItemText>
              </StyledListItemButton>
            )}
          </NavLink>

          <NavLink to={RoutePath.BASKETS_MASTER}>
            {({ isActive }) => (
              <StyledListItemButton 
                disableRipple
                isActive={isActive}
              >
                <Box sx={{pr: 1}}><SchemaOutlinedIcon fontSize="small" sx={{transform: 'rotate(270deg)' }}/></Box> 
                <ListItemText>{RouteLabel.BASKETS_MASTER}</ListItemText>
              </StyledListItemButton>
            )}
          </NavLink>
        </Collapse>

        <NavLink to={RoutePath.ORDERS}>
          {({ isActive }) => (
            <StyledHeaderButton
              isActive={isActive}
              disableRipple
            >
              <Box sx={{pr: 1}}><ContentPasteSharpIcon fontSize="small"/></Box>
              <ListItemText>{RouteLabel.ORDERS}</ListItemText>
              </StyledHeaderButton>
          )}
        </NavLink>

        <StyledHeaderButton
          onClick={() => setOpen({ ...open, reporting: !open.reporting })}
          disableRipple
        >
          <Box sx={{pr: 1}}><AssessmentOutlinedIcon fontSize="small"/></Box>
          <ListItemText>{RouteLabel.REPORTING}</ListItemText>
          {open.reporting ? <ExpandMore fontSize="small"/> : <ChevronRight fontSize="small"/>}
        </StyledHeaderButton>

        <Collapse in={open.reporting}>
          <NavLink to={RoutePath.REPORTING_TURNOVER}>
            {({ isActive }) => (
              <StyledListItemButton 
                disableRipple
                isActive={isActive}
              >
                <Box sx={{pr: 1}}><EuroSharpIcon fontSize="small"/></Box>
                <ListItemText>{RouteLabel.REPORTING_TURNOVER}</ListItemText>
              </StyledListItemButton>
            )}
          </NavLink>
        </Collapse>

        <StyledHeaderButton
          onClick={() => setOpen({ ...open, projects: !open.projects })}
          disableRipple
        >
          <Box sx={{pr: 1}}><AccountTreeOutlinedIcon fontSize="small"/></Box>
          <ListItemText>{RouteLabel.PROJECTS}</ListItemText>
          {open.projects ? <ExpandMore fontSize="small"/> : <ChevronRight fontSize="small"/>}
        </StyledHeaderButton>

        <Collapse in={open.projects}>
          <NavLink to={RoutePath.PROJECTS_SEARCH}>
            {({ isActive }) => (
              <StyledListItemButton 
                disableRipple
                isActive={isActive}
              >
                <Box sx={{pr: 1}}><SearchOutlinedIcon fontSize="small"/></Box>
                <ListItemText>{RouteLabel.PROJECTS_SEARCH}</ListItemText>
              </StyledListItemButton>
            )}
          </NavLink>

          <NavLink to={RoutePath.PROJECTS_OVERVIEW}>
            {({ isActive }) => (
              <StyledListItemButton 
                disableRipple
                isActive={isActive}
              >
                <Box sx={{pr: 1}}><SettingsOutlinedIcon fontSize="small"/></Box>
                <ListItemText>{RouteLabel.PROJECTS_OVERVIEW}</ListItemText>
              </StyledListItemButton>
            )}
          </NavLink>
        </Collapse>

        <NavLink to={RoutePath.QUALITY_CONTROL}>
            {({ isActive }) => (
              <StyledHeaderButton
                isActive={isActive}
                disableRipple
              >
                <Box sx={{pr: 1}}><ReportProblemOutlinedIcon fontSize="small"/></Box>
                <ListItemText>{RouteLabel.QUALITY_CONTROL}</ListItemText>
              </StyledHeaderButton>
            )}
        </NavLink>

        <StyledHeaderButton
          onClick={() => setOpen({ ...open, uploads: !open.uploads })}
          disableRipple
        >
          <Box sx={{pr: 1}}><CloudUploadOutlinedIcon fontSize="small"/></Box>
          <ListItemText>{RouteLabel.UPLOADS}</ListItemText>
          {open.uploads ? <ExpandMore fontSize="small"/> : <ChevronRight fontSize="small"/>}
        </StyledHeaderButton>

        <Collapse in={open.uploads}>
          <NavLink to={RoutePath.UPLOADS_ASSEMBLIES}>
            {({ isActive }) => (
              <StyledListItemButton 
                disableRipple
                isActive={isActive}
              >
                <Box sx={{pr: 1}}><HandymanOutlinedIcon fontSize="small"/></Box>
                <ListItemText>{RouteLabel.UPLOADS_ASSEMBLIES}</ListItemText>
              </StyledListItemButton>
            )}
          </NavLink>

          <NavLink to={RoutePath.UPLOADS_ORDER_REQUESTS}>
            {({ isActive }) => (
              <StyledListItemButton 
                disableRipple
                isActive={isActive}
              >
                <Box sx={{pr: 1}}><ContentPasteSharpIcon fontSize="small"/></Box>
                <ListItemText>{RouteLabel.UPLOADS_ORDER_REQUESTS}</ListItemText>
              </StyledListItemButton>
            )}
          </NavLink>

          <NavLink to={RoutePath.UPLOADS_FILES}>
            {({ isActive }) => (
              <StyledListItemButton 
                disableRipple
                isActive={isActive}
              >
                <Box sx={{pr: 1}}><InsertDriveFileOutlinedIcon fontSize="small"/></Box>
                <ListItemText>{RouteLabel.UPLOADS_FILES}</ListItemText>
              </StyledListItemButton>
            )}
          </NavLink>
        </Collapse>


        </List>
    </StyledSidebar>
  )
}