import { Outlet } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import Protected from './Protected';

export const Auth0ProviderLayout = () => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={`${window.origin}/home`}
    >
      <Protected>
        <Outlet />
      </Protected>
    </Auth0Provider>
)};