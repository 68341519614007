import { useEffect } from "react";
import { Outlet, useMatch, useNavigate } from "react-router-dom"
import { RoutePath } from "../../models/Route"

export const Page = () => {
  const match = useMatch(RoutePath.BASKETS)
  const navigate = useNavigate();

  useEffect (() => {
    if(match) navigate(RoutePath.BASKETS_OPEN)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <div>
      <Outlet />
    </div>
  )
}

export default Page