import { createBrowserRouter } from 'react-router-dom';
import Home from "../app/home/page";
import Parts from "../app/parts/page";
import PartsSearch from "../app/parts/search/page";
import PartsSpares from "../app/parts/spares/page";
import Baskets from "../app/baskets/page";
import BasketOpen from "../app/baskets/open/page";
import BasketOrdered from "../app/baskets/ordered/page";
import BasketMaster from "../app/baskets/master/page";
import Orders from "../app/orders/page";
import Reporting from "../app/reporting/page";
import ReportingTurnover from "../app/reporting/turnover/page";
import Projects from "../app/projects/page";
import ProjectsSearch from "../app/projects/search/page";
import ProjectsOverview from "../app/projects/overview/page";
import Complaints from "../app/complaints/page";
import Uploads from "../app/uploads/page";
import UploadsAssemblies from "../app/uploads/assemblies/page";
import UploadsOderRequests from "../app/uploads/order-requests/page";
import UploadsFiles from "../app/uploads/files/page";
import { RoutePath } from "../models/Route";
import App from '../app/App';
import { Auth0ProviderLayout } from '../auth/Auth0ProviderLayout';
import { LoggedOut } from '../auth/LoggedOut';
import { Public } from '../auth/Public';

const router = createBrowserRouter([
  {
    path: RoutePath.ROOT,
    element: <Public />,
  },
  {
    path: RoutePath.LOGGED_OUT,
    element: <LoggedOut />,
  },
  {
    element: <Auth0ProviderLayout />,
    children: [

      {
        // path: RoutePath.ROOT,
        element: <App />,
        children: [
        {
          path: RoutePath.HOME,
          element: <Home />,
        },
        {
          path: RoutePath.PARTS,
          element: <Parts />,
          children: [
            {
              path: RoutePath.PARTS_SEARCH,
              element: <PartsSearch />,
            },
            {
              path: RoutePath.PARTS_SPARES,
              element: <PartsSpares />,
            },
          ],
        },
        {
          path: RoutePath.BASKETS,
          element: <Baskets />,
          children: [
            {
              path: RoutePath.BASKETS_OPEN,
              element: <BasketOpen />,
            },
            {
              path: RoutePath.BASKETS_ORDERED,
              element: <BasketOrdered />,
            },
            {
              path: RoutePath.BASKETS_MASTER,
              element: <BasketMaster />,
            },
          ],
        },
        {
          path: RoutePath.ORDERS,
          element: <Orders />,
        },
        {
          path: RoutePath.REPORTING,
          element: <Reporting />,
          children: [
            {
              path: RoutePath.REPORTING_TURNOVER,
              element: <ReportingTurnover />,
            },
          ],
        },
        {
          path: RoutePath.PROJECTS,
          element: <Projects />,
          children: [
            {
              path: RoutePath.PROJECTS_SEARCH,
              element: <ProjectsSearch />,
            },
            {
              path: RoutePath.PROJECTS_OVERVIEW,
              element: <ProjectsOverview />,
            },
          ],
        },
        {
          path: RoutePath.QUALITY_CONTROL,
          element: <Complaints />,
        },
        {
          path: RoutePath.UPLOADS,
          element: <Uploads />,
          children: [
            {
              path: RoutePath.UPLOADS_ASSEMBLIES,
              element: <UploadsAssemblies />,
            },
            {
              path: RoutePath.UPLOADS_ORDER_REQUESTS,
              element: <UploadsOderRequests />,
            },
            {
              path: RoutePath.UPLOADS_FILES,
              element: <UploadsFiles />,
            },
          ],
        },
      ]},
  ]},
]);

export default router;
