
import { useAuth0 } from '@auth0/auth0-react';
import Error from '../app/error';
import Loading from '../app/loading';
import { Login } from './Login';

export default function Protected(props: {children: JSX.Element}):JSX.Element {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
  } = useAuth0();

  console.log(user)
  

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error}/>;
  }
  if (isAuthenticated) {
    return (
      props.children
    )
  }
  return <Login />
  
}