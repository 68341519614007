
import { Sidebar } from '../features/Sidebar/Sidebar';
import { Topbar } from '../features/Topbar/Topbar';
import styles from './App.module.css'
import { Outlet } from 'react-router-dom'

export default function App() {

  return (
    <div>
      <div className={styles.layout}>
        <Sidebar />
        <div className={styles.right}>
          <Topbar />
          <main className={styles.main}>
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
}

