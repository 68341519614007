import { useEffect } from "react";
import { Outlet, useMatch, useNavigate } from "react-router-dom"
import { RoutePath } from "../../models/Route"

export const Page = () => {
  const match = useMatch(RoutePath.UPLOADS)
  const navigate = useNavigate();

  useEffect (() => {
    if(match) navigate(RoutePath.UPLOADS_ASSEMBLIES)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <div>
      {/* <p>Uploads</p> */}
      <Outlet />
    </div>
  )
}

export default Page