import logo from '../assets/imgs/logo.png';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../models/Route';
import styles from './Auth.module.css'
import { useEffect } from 'react';


export const Public = () => {
  const nav = useNavigate();

  useEffect(() => {
    nav(RoutePath.HOME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.container}>
      <div><img src={logo} alt="logo" /></div>
      <div className={styles.message}>
      </div>
    </div>
  );
};