import { useEffect } from 'react';

export default function Error({ error }: {error: Error }) {

  useEffect(() => {
    console.error("Errorrrr", error);
  }, [error]);

  return (
    <div>
      <p>Er ging iets mis.</p>
    </div>
  );
}
