import { Logout } from '../../auth/Logout'
import styles from './Topbar.module.css'
import { useAuth0 } from '@auth0/auth0-react'

export const Topbar = ():JSX.Element => {
  const { user } = useAuth0()
  const userName = user?.nickname || ''
  const userNameCapitalized = userName?.charAt(0).toUpperCase() + userName?.slice(1) || ''

  return (
    <div className={styles.topbar}>
      <div className={styles.right}>
        <div className={styles.user}>
          {userNameCapitalized} 
        </div>
        <div className={styles.logout}>
          <Logout />
        </div>
      </div>
    </div>
  )
}